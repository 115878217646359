import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

const conicGradient = `
conic-gradient(
  at -10% 80%,
  ${Color.Primitive.Secondary}50 1%,
  transparent 22%,
  transparent 25%
)
`;

export const Colors = {
  ...Colors_default,

  ProgressContainerBackground: 'transparent',
  ProgressSectionBackground: `radial-gradient(ellipse at right -2%, ${Color.Primitive.Accent}10, transparent 83%, transparent)`,

  HeadingStyle: 'normal',
  Heading: Color.Primitive.Primary,

  WidgetBackgroundImage: conicGradient,
};
